import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { forgotPasswordApi, resetPasswordApi, sendEmailForMFA, sendSMSForMFA } from "api/auth";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

const ForgetPasswordPage = (props) => {
  // Meta title
  document.title = "Forget Password | Welfare Administration System";

  // Redux
  const dispatch = useDispatch();
  const forgetError = useSelector(state => state.ForgetPassword.forgetError);
  const forgetSuccessMsg = useSelector(state => state.ForgetPassword.forgetSuccessMsg);

  // Local state
  const [codeSent, setCodeSent] = useState(false);
  const navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      contact: "", // Single input for both email and mobile
      code: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      contact: Yup.string().required("Please Enter Your Email or Mobile Number"),
      code: Yup.string().when('codeSent', {
        is: true,
        then: Yup.string().required("Please Enter the Code"),
      }),
      newPassword: Yup.string().when('codeSent', {
        is: true,
        then: Yup.string().required("Please Enter New Password"),
      }),
    }),
    onSubmit: async (values) => {
      try {
        console.log(values);
        const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.contact); // Regex to check if it's an email
        const isMobile = /^\d+$/.test(values.contact); // Regex to check if it's a mobile number

        if (codeSent) {
          const response = await resetPasswordApi({
            userName: values.contact,
            password: values.newPassword,
            code: values.code
          });
          if (response.status === "Success") {
            Swal.fire('Success!', 'Password reset successfully.', 'success');
            navigate('/login');
          } else {
            Swal.fire('Error!', response.message, 'error');
          }
        } else {
          const resp = await forgotPasswordApi({ email: values.contact, userType: 'member' });
          if (resp && resp?.status === 'Success') {
            showDialog('Success', 'OTP sent successfully.', 'success');
            setCodeSent(true);
          } else {
            showDialog('Error', resp?.message, 'error')
          }
        }
      } catch (error) {
        showDialog('Error', error.message, 'error');
      }
    }
  });

  const showDialog = (title, message, type) => {
    Swal.fire(title, message, type);
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Enter Your Email or Mobile Number to Reset Password.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError.message ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError.message}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email or Mobile Number</Label>
                        <Input
                          name="contact"
                          className="form-control"
                          placeholder="Enter email or mobile number"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.contact || ""}
                          invalid={
                            validation.touched.contact && validation.errors.contact ? true : false
                          }
                        />
                        {validation.touched.contact && validation.errors.contact ? (
                          <FormFeedback type="invalid">{validation.errors.contact}</FormFeedback>
                        ) : null}
                      </div>

                      {codeSent && (
                        <>
                          <div className="mb-3">
                            <Label className="form-label">New Password</Label>
                            <Input
                              name="newPassword"
                              className="form-control"
                              placeholder="Enter password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.newPassword || ""}
                              invalid={
                                validation.touched.newPassword && validation.errors.newPassword ? true : false
                              }
                            />
                            {validation.touched.newPassword && validation.errors.newPassword ? (
                              <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">CODE</Label>
                            <Input
                              name="code"
                              className="form-control"
                              placeholder="Enter code"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.code || ""}
                              invalid={
                                validation.touched.code && validation.errors.code ? true : false
                              }
                            />
                            {validation.touched.code && validation.errors.code ? (
                              <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                            ) : null}
                          </div>
                        </>
                      )}
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            {codeSent ? 'Submit Code' : 'Reset'}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} SHOFCO SUN.Welfare Member{" "}
                  <i className="mdi mdi-heart text-danger" /> Portal
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);